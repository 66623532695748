import React, { Component } from 'react';

// Components
import { HeaderComponent } from '../../components/header_component/header_component';
import { LoadingComponent } from '../../components/loading_component/loading_component';
import { ValidationComponent } from '../../components/validation_component/validation_component';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config'
import { HelperUser } from '../../../helpers/helper_user/helper_user'
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies'

// Actions file
import { onActionToPerform } from '../../../actions/actions';

export class ValidationPage extends Component {

    constructor() {
        super();
        this.state = {
            currentproject: "",
            study_id: "",
            patient_id: "",
            data_type: "",
            images_loaded: false,
            images: false,
            image_to_edit: false,
            edit_option: false,
            start: 0,
            images_to_show: false
        }
        this.user = {};

        document.addEventListener('keydown', event => this.handleKeyboard(event));
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" });
        } else {
            HelperConfig.init().then(() => {
                HelperStudies.initStudy().then(study_info => {
                    this.user = HelperUser.getUser();
                    this.setState(study_info.state);
                    HelperStudies.loadStudyforReview(study_info,this.user).then((images) => {
                        let requirements = HelperStudies.getCurrentProjectObj().image_requirements;
                        if (requirements) {
                            HelperStudies.checkStudyState();
                        }
                        let review_images = HelperStudies.sortImagesforReview(images);
                        this.setState({
                            images: review_images,
                            images_loaded: true,
                            show_study_state: requirements? true : false,
                            validated: study_info.validated
                        });
                        this.onActionToPerform("VALIDATION", { action: "INIT-VALIDATION", value: { image: this.state.image } });
                    });
                });    
            });
        }
    }

    handleKeyboard(event) {
        onActionToPerform("VALIDATION-KEY", { event, value: this.state.images_to_show }, this);
    }

    onActionToPerform(actionType, action) {
        if (action && action.value) {
            action.value.from = "validation";
            onActionToPerform(actionType, action, this);
        }
    }

    renderValidationComponent() {
        if (this.state.images && this.state.images.length > 0) {
            let proj_obj = HelperStudies.getCurrentProjectObj();
            let qc = proj_obj.qc? proj_obj.qc.type : false; 
            return (
                <ValidationComponent
                    images={this.state.images_to_show}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    image_to_edit={this.state.image_to_edit}
                    edit_option={this.state.edit_option}
                    images_after={this.state.images.length > (this.state.start + 4)}
                    images_before={this.state.start > 0}
                    validated={this.state.validated}
                    show_study_state={this.state.show_study_state}
                    qc={qc}
                />
            );
        } else {
            return (
                <LoadingComponent
                    site={"validation"}
                    user_type={this.user.user_type}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
            );
        }
    }

    render() {
        return (
            <div className="grid-frame vertical">
                <HeaderComponent
                    mode={"validation"}
                    active={this.state.images_loaded}
                    user={this.user}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    study={this.state}
                />

                {this.renderValidationComponent()}
            </div>
        );
    }
}