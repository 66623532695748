import React, { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Components
import { DBStatus } from './db_status';
// Helpers
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies';
import { HelperConfig } from '../../../helpers/helper_config/helper_config';
// Styles
import { useStyles } from './db_styles';


import { HelperUser } from '../../../helpers/helper_user/helper_user';
import coales_ids from '../../../config/coales_complete_ids.json';


export const DatabaseTable = (props) => {
    
    const classes = useStyles();
    const [page, setPage] = useState(props.initial_page);
    const initialRows = props.from === "studies"? Math.floor((window.innerHeight - 320)/62) : Math.floor((window.innerHeight - 320)/53);
    const [rowsPerPage, setRowsPerPage] = useState(initialRows);
    const data = HelperStudies.createStudiesTableData(props.from, props.items);
    const rows = data.rows;
    const columns = data.columns;
    const user = HelperUser.getUser();

    useEffect(() => {
        setPage(HelperStudies.getCurrentPage());
    },[props.items]);

    const handleChangePage = (event, newPage) => {
        HelperStudies.setCurrentPage(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const renderStatusCell = (column, value, row) => {
        return (
            <TableCell key={column.id} align={"center"} className={classes.cell}>
                <DBStatus
                    status={value}
                    row={row}
                    onactiontoperform={props.onactiontoperform}
                />
            </TableCell>
        )
    }
    const renderTableRowCell = (column, value, row) => {
        if (column.id !== "status") {
            let classNames = row.is_open && user.user_type === "Editor"? classes.cell + " open" : classes.cell;
            return (
                <TableCell key={column.id} align={"center"} className={classNames}
                    onClick={() => props.onactiontoperform("GENERAL", {
                        action: "GO_TO_PAGE", value: {
                            project: row.project,
                            id_study: row.study_id,
                            id_patient: row.patient,
                            id_patient_show: row.patient_show,
                            date: row.date,
                            page: props.page,
                            from: props.from,
                            type: row.data_type,
                            editor: row.editor,
                            db_status: row.status
                        }
                    })}
                >
                    {column.format && typeof value === 'number' ? column.format(value) : value}
                </TableCell>
            );
        } else {
            return (renderStatusCell(column, value, row))
        }
    }
    const renderTableBody = (rows) => {
        return (
            <TableBody>{
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    let classNames = row.is_open && user.user_type === "Editor"? classes.row + " open" : classes.row;
                    let style = {};
                    if (Number.isInteger(row.validated_by)) {
                        style.backgroundColor = HelperConfig.getStyle("review","validation_steps")[row.validated_by];
                    } else if (row.validated_by.length > 0) {
                        classNames += " validated";
                    }
                    // HARDCODE FOR BLUE STUDIES (IDs WITH COMPLETE DATA)
                    // else if (["lea.tst95@gmail.com"].includes(user.user_email)) {
                        const project = HelperStudies.getCurrentProject();
                        if (["aortic-coa","coales"].includes(project) && coales_ids.includes(row.study_id)) {
                            style.backgroundColor = "#002e81";
                        }
                    // }
                    // ---------------------------------------

                    let alert = row.is_open && user.user_type === "Editor"? <span>Another editor is working in this study</span> : <></>;
                    return (
                        <TableRow className={classNames} style={style} role="checkbox" tabIndex={-1} key={row.code} >
                            {columns.map((column) => {
                                return (renderTableRowCell(column, row[column.id], row))
                            })}
                            {alert}
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }
    const renderTableHeader = (columns) => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={"center"}
                            style={{ color: "white", fontWeight: "bold", minWidth: column.minWidth, backgroundColor: "#21211ff0" }}
                        >
                            {renderTableHeaderCell(column)}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }
    const renderTableHeaderCell = (column) => {
        if (column.id === "status") {
            return column.label;
        } else {
            return (
                <TableSortLabel
                    active={props.sort_by === column.id}
                    direction={props.sort_direction}
                    onClick={() => { props.onactiontoperform("GENERAL", { action: "SORT_BY", value: column.id }) }}
                    style={{ color: "white", fontWeight: "bold" }}
                >
                    {column.label}
                </TableSortLabel>
            );
        }
    }
    return (
        <Paper className={classes.table}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    {renderTableHeader(columns)}
                    {renderTableBody(rows)}
                </Table>
            </TableContainer>
            <TablePagination
                // rowsPerPageOptions={[]}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{ color: "white" }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className={classes.pagination}>
                <span style={{ marginRight: "10px" }}>Current page:</span>
                <Select
                    size="small"
                    onChange={(e) => handleChangePage(e, e.target.value-1)}
                    value={page+1}
                    MenuProps={ { PaperProps: { sx: { maxHeight: 360 } } } }
                >
                    {[...Array(Math.ceil(rows.length/rowsPerPage)).keys()].map(i => {
                        return (
                            <MenuItem id={i+1} key={i+1} value={i+1}>{i+1}</MenuItem>
                        );
                    })}
                </Select>
            </div>
        </Paper>
    );
}