import React, { Component } from 'react';

// Components
import { HeaderComponent } from './../../components/header_component/header_component';
import { LoadingComponent } from './../../components/loading_component/loading_component';
import { InactivityAlertComponent } from './../../components/alert_component/inactivity_alert_component';
import { MeasurementsComponent } from './../../components/measurements_component/measurements_component';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config'
import { HelperUser } from './../../../helpers/helper_user/helper_user'
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies'

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class MeasurementsPage extends Component {

    constructor() {
        super();
        this.state = {
            currentproject: "",
            study_id: "",
            patient_id: "",
            data_type: "",
            images_loaded: false,
            images: []
        }
        this.user = {};

        window.addEventListener('beforeunload', (event) => {
            if (this.user.user_type === "Editor") {
                HelperStudies.setStudyOpen(this.state.study_id,false);
            }
        });
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" })
        } else {
            HelperConfig.init().then(() => {
                HelperStudies.initStudy().then(study_info => {
                    HelperStudies.checkStudyOpen(study_info.study_id,study_info.editor).then(is_open => {
                        if (is_open) {
                            this.onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "studies", from: "studies" } });
                        } else {
                            this.user = HelperUser.getUser();
                            this.setState(study_info.state);
                            if (this.user.user_type === "Reviewer") {
                                HelperStudies.loadStudyforReview(study_info,this.user).then((images) => {
                                    this.setState({
                                        images,
                                        images_loaded: true
                                    });
                                });    
                            } else {
                                HelperStudies.getFilesSelectedToLoad(study_info.study_id, study_info.editor, this.user).then((result) => {
                                    if (result) {
                                        HelperStudies.setImagesToLoad(result);
                                    }
                                    HelperStudies.loadStudy(study_info, this.user).then((images) => {
                                        if (this.user.user_type === "Editor") { this.timerManager("on"); }
                                        this.setState({
                                            images,
                                            images_loaded: true
                                        });
                                    });
                                });
                            }   
                        }
                    });
                }); 
            });
        }
    }

    componentWillUnmount() {
        if (this.user.user_type === "Editor") {
            this.timerManager("off");
            HelperStudies.setStudyOpen(this.state.study_id,false);
        }
    }

    checkInactivity() {
        if (!this.state.timer_expired) {
            HelperStudies.checkLastUpdateBeforeMinutes(this.state.study_id, this.user.user_email, HelperConfig.getInactivityPeriod()).then(result => {
                if (!result) {
                    this.timerManager("off");
                    this.setState({ timer_expired: true });
                }
            });    
        }
    }

    timerManager(state) {
        if (state === "on") {
            this.timer = setInterval(() => this.checkInactivity(), HelperConfig.getInactivityCheck()*1000);
        } else if (state === "off") {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    renderMeasurements() {
        if (this.state.images_loaded) {
            let images_to_show = this.state.images.analysis_images;
            if (this.user.user_type === "Reviewer") {
                images_to_show = images_to_show.filter(img => img.validated);
            }
            return (
                <MeasurementsComponent
                    images={images_to_show}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    mode={this.user.user_type}
                    editor={this.state.editor}
                />
            );
        } else {
            return (
                <LoadingComponent
                    site={"measurements"}
                    user_type={this.user.user_type}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
            );
        }
    }

    render() {
        return (
            <div className="grid-frame vertical">
                <HeaderComponent
                    mode={"measurements"}
                    user={this.user}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    study={this.state}
                    active={this.state.images_loaded}
                />

                {this.renderMeasurements()}

                <InactivityAlertComponent
                    show={this.state.timer_expired}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    handle_close={() => { this.timerManager("on"); this.setState({ timer_expired: false }); }}
                />
            </div>
        );
    }
}