import { DataProvider } from './../../dataprovider/dataprovider';
import { HelperExport } from './../helper_export/helper_export';
var job = 0;
var myVar;

export class HelperJobs {
    static computeNew(export_type, project_id, input_params) {
        console.log("Generating export")
        var {editor, imtype, mode, dataType, viewHeaders, studyType, exportType, images, singleFile, allFiles, normalMeas, caliperMeas} = input_params;
        var params = {};
        var call = "";
        switch (export_type) {
            case "IAP_Export":
                switch (exportType) {
                    case "segmentations":
                        params = {
                            project: project_id,
                            editor: editor,
                            data: dataType,
                            mode: mode,
                            only_study_type: studyType,
                            images: images,
                            image_type: imtype,
                            single_file: singleFile,
                            all_files: allFiles,
                            user: localStorage.getItem("email")
                        }
                        call = "CREATE_EXPORT";
                        break;
                    case "measurements":
                        params = {
                            project: project_id,
                            editor: editor,
                            image_type: imtype,
                            data: dataType,
                            mode: mode,
                            imview_header: viewHeaders,
                            include_normal: normalMeas,
                            include_caliper: caliperMeas,
                            user: localStorage.getItem("email")
                        }
                        call = "CREATE_EXPORT_MEAS";
                        break;
                    default:
                        break;
                }
                break;
            case "DL_Export":
                params = {};
                call = "";
                break;
            default:
                break;
        }

        if (project_id !== undefined) {
            
            let token = localStorage.getItem("token");
            DataProvider("JOBS", call, params, token).then((result) => {
                // checkJobStatus(result, params);
                // console.log(result)
            })
        }
    }
    static getExportStatus(){
        return checkingStatus().then((result)=>{
            return result
        });
    }
}

function checkJobStatus(job_running) {
    job = job_running;
    myVar = setInterval(checkingStatus, 5000);

}

function checkingStatus() {

    return getJobStatus(job.manifest.id).then((result)=>{
        return result;
    })

}



function getJobStatus(job_id) {
    var params = {
        id: job_id
    }
    let token = localStorage.getItem("token");
    return DataProvider("JOBS","GET_JOB", params, token).then((job_obj) => {
        // console.log(result);
        var result = job_obj["status"]
        if (result === "finished" || result === "processing finished") {
            clearInterval(myVar);
            let getMinioName = job_obj.log[job_obj.log.length -1];
            // console.log(getMinioName);
            
            HelperExport.downloadZip(getMinioName).then((result)=>{
                return result;
            })
            console.log("Result",result)
        } else if (result === "error") {
            clearInterval(myVar);
        }
        return result;
    })
}
