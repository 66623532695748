import { findWhere } from "underscore";
import { getDopplerRegion } from "../../modules/dicom_module";

export class HelperMeasurementsMMode {

    static getMModeMeasurements(measurements, segmentation, image) {
        const measures2render = [];
        if (measurements) {
            measurements.map((measure) => {
                measure.value = [];
                if (measure.type === "distance") {
                    if (segmentation[0]?.lines.length > 0) {
                        const line = findWhere(segmentation[0].lines, { name: measure.line });
                        if (line) {
                            measure.value = this.getDistance(line.points, measure, image);
                        }
                    }
                } else if (measure.type === "line-distance") {
                    if (segmentation[0]?.lines.length > 1) {
                        const line1 = findWhere(segmentation[0].lines, { name: measure.lines[0] });
                        const line2 = findWhere(segmentation[0].lines, { name: measure.lines[1] });
                        if (line1 && line2) {
                            measure.value = this.getLineDistance(line1.points, line2.points, measure, image);
                        }
                    }
                }
                measures2render.push(measure)
            });
        }
        return measures2render;
    }

    static getDistance(points, measure, image) {
        // const flow_region = getDopplerRegion(image.dicom.metadata.doppler_regions, image.modality);
        const flow_region = image.metadata.flow_region;
        let physical_delta = flow_region["physical_delta_" + measure.axis];
        if (!physical_delta) {
            physical_delta = flow_region["spacing_" + measure.axis];
        }
        const value = [];
        if (points?.length > 0) {
            for (const onset of image.cardiac_events) {
                const a = findWhere(points, { x: onset[measure.formula[0]] });
                const b = findWhere(points, { x: onset[measure.formula[1]] });
                if (a && b) {
                    value.push(Math.abs(a[measure.axis] - b[measure.axis]) * physical_delta);
                }
            }
        }
        return value;
    }

    static getLineDistance(points1, points2, measure, image) {
        const flow_region = image.metadata.flow_region;
        let physical_delta = flow_region["physical_delta_" + measure.axis];
        if (!physical_delta) {
            physical_delta = flow_region["spacing_" + measure.axis];
        }
        const value = [];
        if (points1?.length > 0 && points2?.length > 0) {
            for (const onset of image.cardiac_events) {
                const a = findWhere(points1, { x: onset[measure.cardiac_event] });
                const b = findWhere(points2, { x: onset[measure.cardiac_event] });
                if (a && b) {
                    value.push(Math.abs(a[measure.axis] - b[measure.axis]) * physical_delta);
                }
            }
        }
        return value;
    }
}