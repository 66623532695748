import React, { Component } from 'react';

// Components
import { HeaderComponent } from './../../components/header_component/header_component';
import { LoadingComponent } from './../../components/loading_component/loading_component';
import { OverviewComponent } from './../../components/overview_component/overview_component';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config'
import { HelperUser } from './../../../helpers/helper_user/helper_user'
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies'

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class OverviewPage extends Component {

    constructor() {
        super();
        this.state = {
            currentproject: "",
            study_id: "",
            patient_id: "",
            data_type: "",
            images_loaded: false,
            images: []
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" })
        } else {
            HelperConfig.init().then(() => {
                HelperStudies.initStudy().then(study_info => {
                    this.user = HelperUser.getUser();
                    this.setState(study_info.state);
                    HelperStudies.getFilesSelectedToLoad(study_info.study_id, study_info.editor, this.user).then((result) => {
                        if (result) {
                            HelperStudies.setImagesToLoad(result);
                        }
                        HelperStudies.loadStudy(study_info, this.user).then((images) => {
                            this.setState({
                                images,
                                images_loaded: true
                            });
                        });
                    });    
                });
            });
        }
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    renderOverview() {
        if (this.state.images_loaded) {
            return (
                <OverviewComponent
                    images={this.state.images.loaded_images}
                />
            )
        } else {
            return (
                <LoadingComponent
                    site={"overview"}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
            );
        }
    }

    render() {
        return (
            <div className="grid-frame vertical">
                <HeaderComponent
                    mode={"overview"}
                    user={this.user}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    study={this.state}
                    active={this.state.images_loaded}
                />

                {this.renderOverview()}
            </div>
        );
    }
}