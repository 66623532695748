import React from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperSegmentation } from './../../../helpers/helper_segmentation/helper_segmentation';

// Components
import { MeasurementsBox } from '../measurements_component/measurements_box';
import { CaliperComponent } from './caliper_component';

// AUX functions
import classNames from 'classnames';
import Slider from '@material-ui/core/Slider';
import { findWhere } from 'underscore';

export const SegmentationComponent = ({
        image,
        state,
        on_action_to_perform
    }) => {

    const renderProgress = () => {
        if (!["2D","M-Mode"].includes(image.modality) && state.current_action !== "selecting-cycles") {
            const cycle = ["finished","editing"].includes(state.current_action)? image.cardiac_events.length : state.current_cycle + 1;
            return cycle + "/" + image.cardiac_events.length + " CC";
        }
    }

    const renderMeasurements = () => {
        if (state.show_measurements && !["selecting-cycles"].includes(state.current_action)) {
            return (
                <MeasurementsBox
                    image={image}
                    zero_line={0}
                    mode={state.current_action}
                    display={"flex"}
                />
            );
        }
    }

    const renderCaliper = () => {
        if (state.current_action === "caliper") {
            return (
                <CaliperComponent
                    image={image}
                    tool_state={state.caliper_tool}
                    show_options={state.show_caliper_options}
                    state={{ loaded: state.loaded, current_cycle: state.current_cycle, current_event: state.current_event }}
                    on_action_to_perform={on_action_to_perform}
                />
            );
        }
    }

    const renderLegend = () => {
        if (state.show_legend && state.control_points?.length > 0 && !["caliper","selecting-cycles"].includes(state.current_action)) {
            const lines = HelperSegmentation.getSegmentationLines(image, state.current_cycle, state.current_event);
            const line = image.modality === "2D"? findWhere(lines, { name: state.current_line }) : lines[state.current_cycle];
            if (line) {
                const cp_colors = HelperConfig.getStyle("segmentation","cp_colors");
                return (
                    <>
                        {renderLegendHeader(line)}
                        {renderLegendContent(line, cp_colors)}
                    </>
                );
            }
        }
    }

    const renderLegendHeader = (line) => {
        const patterns = HelperConfig.getPossiblePatterns(image.type, image.modality);
        if (patterns.length > 1) {
            return <div className="legend-header">[ {line.pattern} ]</div>
        }
    }

    const renderLegendContent = (line, cp_colors) => {
        return state.control_points.map((cp) => {
            const cp_color = findWhere(cp_colors, { type: cp.type });
            const color = cp_color? cp_color.color : "white";
            const point = findWhere(line.points, { id: cp.id });
            const style = {
                backgroundColor: point && point.x && point.y? color : "transparent",
                borderColor: color
            };
            return (
                <div key={cp.id}>
                    <div className="circle" style={style}></div>
                    <div className="text">{cp.name_to_show}</div>
                </div>
            );
        });
    }

    const renderSlider = () => {
        if (image.modality === "2D" && (state.current_action === "editing-thickness" || (state.current_action === "editing" && image.cardiac_events.length > 0))) {
            const config = HelperConfig.getToolConfig("2D","normal");
            return (
                <React.Fragment>
                    <span>Thickness:</span>
                    <Slider
                        defaultValue={config.default_epi_thickness}
                        min={config.min_epi_thickness}
                        step={config.step_epi_thickness}
                        max={config.max_epi_thickness}
                        onChange={(e, val) => { on_action_to_perform({ action: "CHANGE-THICKNESS", value: { image, thickness: val }}) }}
                        aria-labelledby="continuous-slider"
                    />
                </React.Fragment>
            );
        }
    }

    const renderTimeEvents = () => {
        if (image.modality === "2D" && image.cardiac_events.length > 0) {
            return image.cardiac_events.map((cycle, i) => {
                const style_d = { borderColor: HelperConfig.getStyle("cycletiming","end_diastole").color };
                const style_s = { borderColor: HelperConfig.getStyle("cycletiming","end_systole").color };
                if (state.current_frame === cycle.end_diastole) {
                    style_d.backgroundColor = style_d.borderColor;
                    style_d.color = "#000";
                } else if (state.current_frame === cycle.end_systole) {
                    style_s.backgroundColor = style_s.borderColor;
                }
                return (
                    <React.Fragment>
                        <div key={cycle.end_diastole} style={style_d} onClick={() => { on_action_to_perform({ action: "CHANGE-TIME-EVENT", value: { image, cycle: i, event: "end_diastole", frame: cycle.end_diastole }}) }}>End diastole<br /><b>CC {i + 1}</b></div>
                        <div key={cycle.end_systole} style={style_s} onClick={() => { on_action_to_perform({ action: "CHANGE-TIME-EVENT", value: { image, cycle: i, event: "end_systole", frame: cycle.end_systole }}) }}>End systole<br /><b>CC {i + 1}</b></div>
                    </React.Fragment>
                )
            });
        }
    }

    const renderShapes = () => {
        if (["2D","M-Mode"].includes(image.modality) && state.current_action !== "caliper") {
            const shapes = HelperConfig.getShapes(image.type, "normal", false);
            const lines = HelperConfig.getLines(image.type, "normal", false);
            const colors = HelperConfig.getStyle("segmentation", "line_colors");
            // const is_active = !["segmenting","editing_thickness","editing_segmentation"].includes(state.current_action);
            if (shapes) {
                return shapes.map((shape) => {
                    const color = colors[lines.indexOf(shape.lines[0])];
                    const is_current = ["finished","editing"].includes(state.current_action)? true : state.current_line?.includes(shape.name);
                    const style = {
                        borderColor: color,
                        backgroundColor: is_current? color : "transparent"
                    };
                    return (
                        <div key={shape.name}>
                            {/* <div className={classNames({"active":is_active},"shape")} style={style} */}
                            <div className={"shape"} style={style}
                                // onClick={() => { if (is_active) { on_action_to_perform({ action: "CHANGE-SHAPE", value: { image, shape: shape.name }}) } }}
                                // onMouseEnter={(e) => { if (is_active && !is_current) { e.target.style.backgroundColor = color }}}
                                // onMouseLeave={(e) => { if (is_active && !is_current) { e.target.style.backgroundColor = "transparent" }}}
                            >
                                {shape.name}
                            </div>
                            <svg fill={color} onClick={() => { on_action_to_perform({ action: "REMOVE-SHAPE", value: { image, shape: shape.name }}) }} focusable="false" viewBox="0 0 24 24">
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"></path>
                            </svg>
                        </div>
                    );
                });
            }
        }
    }

    return (
        <React.Fragment>
            <div className="progress">
                {renderProgress()}
            </div>
            <div className="legend">
                {renderLegend()}
            </div>
            <div className="slider">
                {renderSlider()}
            </div>
            <div className="shapes">
                {renderShapes()}
            </div>
            <div className="time-events">
                {renderTimeEvents()}
            </div>
            <div className="measurements-box">
                {renderMeasurements()}
            </div>
            <div className="caliper-box">
                {renderCaliper()}
            </div>
        </React.Fragment>
    );
}