import React, { Component } from 'react';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
// components
import { PlatformNameComponent } from './../../components/platform_name/platform_name_component'

export class UserPage extends Component {
    constructor() {
        super();
        this.state = {
            user_types: []
        }
    }
    componentDidMount() {
        let user_type = HelperUser.getUserType();
        let myC = this;
        const urlParams = new URLSearchParams(window.location.search);
        let stay = urlParams.get('stay');
        HelperConfig.init().then(() => {
            if (user_type !== null && user_type !== "null" && !stay) {
                let rol2Render = HelperUser.getRoleFromConfig(user_type)
                this.setUrl(rol2Render.redirect2page);
            } else {
                HelperUser.getUserRolesFromDB().then((user) => {
                    if (!user.error && user.user_role) {
                        HelperUser.setUserRoles(user.user_role);
                        myC.setState({
                            user_types: user.user_role
                        });
                    } else {
                        HelperUser.removeUser();
                        myC.setUrl("login");
                    }
                })
            }
        });
    }
    setUrl(url) {
        if (url !== false) {
            var browserHistory = this.props.history;
            browserHistory.push("/" + url);
        } else {
            alert("URL not compatible");
        }
    }
    onClickLoginButton(rol, redirect2page) {
        HelperUser.setUserType(rol);
        this.setUrl(redirect2page);
    }
    renderUserRole(user_type) {
        let rol2Render = HelperUser.getRoleFromConfig(user_type)
        if (rol2Render) {
            return (
                <div key={user_type} className="grid-item button_user_type_selectable" style={{ backgroundColor: rol2Render.color }} onClick={() => { this.onClickLoginButton(rol2Render.type, rol2Render.redirect2page) }}>
                    <div className="button_user_type_text"> {rol2Render.name_to_show}</div>
                    <div className="button_user_type_description"> {rol2Render.description}</div>
                </div>
            )
        }
    }
    renderRoles() {
        let roles = this.state.user_types;
        if (roles) {
            return (
                <>
                    <div className="login-text">Login as: </div>
                    {roles.map(rol => this.renderUserRole(rol))}
                </>
            );    
        }
    }
    render() {
        return (
            <div className="grid-frame vertical user-page">
                <PlatformNameComponent type={"medium"}/>
                <div className="popup1">
                    {this.renderRoles()}
                </div>
            </div>
        );
    }
}