import React from 'react';

// Components
import { DicomViewerComponent } from '../data_viewer_component/DICOM/dicom_viewer_component';
import { GeneralIcons } from './../icons_component/general_icons';
import { ImageMenuIcons } from './../icons_component/image_menu_icons';
import { ModalityIcons } from './../icons_component/modality_icons';
import { TopBarComponent } from './../selection_component/top_bar_component';
import { ChatComponent } from './../chat_component/chat_component';
import { ScoreComponent } from './../score_component/score_component';
import { StudyStateComponent } from './../study_state_component/study_state_component';

// AUX functions
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import classNames from 'classnames';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperImages } from './../../../helpers/helper_images/helper_images';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const ValidationComponent = ({
    images,
    on_action_to_perform,
    image_to_edit,
    edit_option,
    images_after,
    images_before,
    validated,
    show_study_state,
    qc
}) => {

    const onActionToPerformLabel = (action) => {
        action.action = "CHANGE-LABEL";
        action.value = {
            image: image_to_edit,
            value: action.value
        };
        on_action_to_perform("VALIDATION", action);
    }

    const handleQCclick = (image) => {
        if (qc.includes("complete")) {
            on_action_to_perform("VALIDATION", { action: "EDIT", value: { image, type: "qc" } })
        } else {
            let new_value = image.accepted? (image.accepted === "yes"? "no": "yes") : "yes";
            on_action_to_perform("VALIDATION", { action: "QC-ACCEPT", value: { image, type: new_value } });
        }
    }

    const getEditOptions = (image) => {
        let edit_options = ["label"];
        if (image.view && image.type) {
            if (HelperImages.imageHasCycles(image)) {
                edit_options.push("cycletiming")
            }
            if (image.is_annotated || !HelperImages.imageHasCycles(image)) {
                edit_options.push("segmentation")
            }
        }
        return edit_options;
    }

    const renderFinishedNotice = () => {
        if (validated) {
            return (
                <div className="finished-alert">
                    This study validation is FINISHED and cannot be edited
                    <div className="reopen-button" onClick={() => { on_action_to_perform("VALIDATION", { action: "REOPEN-ANALYSIS", value: { image: false } }) }}>REOPEN</div>
                </div>
            );
        }
    }

    const renderStudyState = () => {
        if (show_study_state) {
            return (
                <StudyStateComponent
                    site={"validation"}
                    allow_manual={validated===false}
                />
            );    
        }
    }

    // RENDER NAVIGATION BUTTONS
    const renderPreviousIcon = () => {
        return (
            <div className="floating-vertically-centered-div-left" >
                <div className={classNames({ "inactive": !images_before }, "navigation")} onClick={() => { if (images_before) { on_action_to_perform("VALIDATION", { action: "GO-BACK", value: { step: 4 } }) } }}>
                    <GeneralIcons type={"previous"} width="24" height="24" />
                </div>
            </div>
        );
    }

    const renderNextIcon = () => {
        return (
            <div className="floating-vertically-centered-div-right" >
                <div className={classNames({ "inactive": !images_after }, "navigation")} onClick={() => { if (images_after) { on_action_to_perform("VALIDATION", { action: "GO-NEXT", value: { step: 4 } }) } }}>
                    <GeneralIcons type={"next"} width="24" height="24" />
                </div>
            </div>
        )
    }

    // RENDER EVALUATION AREA
    const renderValidationComp = () => {
        const n_of_images = 4;
        const ns = [...Array(n_of_images).keys()];
        return (
            <div className="evaluation-container">
                <div className="evaluation-area">
                    {ns.map(n => {
                        const file = images[n];
                        if (file?.missing_image) {
                            return (
                                <div key={n} className="image-evaluation missing">
                                    <div className="black-box"><strong><i>{file.type}</i></strong><br/>missing</div>
                                </div>
                            );
                        } else if (file) {
                            return (
                                <div key={n} className="image-evaluation">
                                    <DicomViewerComponent
                                        site={"validation"}
                                        image_to_show={file}
                                        play_multiframe={true}
                                        play_by_default={false}
                                        show_player_controls={false}
                                    />
                                    <div className="labels-area">
                                        <ModalityIcons type={file.modality} width={"30px"} height={"30px"} background={false} />
                                        <div style={{ fontSize: "13px", marginLeft: "10px" }}>{file.modality}</div>
                                        <div className="divider">|</div>
                                        <div>{HelperConfig.getImageTypeNameToShow(file.type)} </div>
                                        <div className="divider">|</div>
                                        <div>{file.view}</div>
                                    </div>
                                    {renderEvaluationToolbox(file)}
                                </div>
                            );
                        } else {
                            return <div key={n} className="image-evaluation"></div>;
                        }
                    })}
                </div>
            </div>
        );
    }

    // RENDER EVALUATION MENU
    const renderEvaluationToolbox = (image) => {
        const edit_options = getEditOptions(image);
        let check_color = image.validated? "#3FCA1C" : "#888";
        let check_title = image.validated? "Validated image" : "Image not validated";
        if (!validated) {
            return (
                <div className="validation-toolbox">
                    <div title={check_title} onClick={() => { on_action_to_perform("VALIDATION", { action: "VALIDATE", value: { image } }) }}>
                        <ImageMenuIcons type={"done"} color={check_color} width="31" height="31" />
                    </div>
                    {renderQCbutton(image)}
                    <div id="edit-div" className={classNames({ "inactive": edit_options.length === 0 }, { "focusable": edit_options.length > 0 })}>
                        <ImageMenuIcons type={"edit"} color={"#FEA218"} width="31" height="31" />
                        <div className="edit-box" >
                            {edit_options.map((option) => {
                                return <div key={option} className="edit-type" onClick={() => { on_action_to_perform("VALIDATION", { action: "EDIT", value: { image: image, type: option } }) }}>{option}</div>;
                            })}
                        </div>
                    </div>
                    <div title="Comments" onClick={() => { on_action_to_perform("VALIDATION", { action: "EDIT", value: { image, type: "comments" } }) }}>
                        {renderCommentIcon(image)}
                    </div>
                    <div title="Remove image" onClick={() => { on_action_to_perform("VALIDATION", { action: "REMOVE", value: { image } }) }}>
                        <ImageMenuIcons type={"cross"} style={{ fill: "#F20B0B" }} width="26" height="26" />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="validation-toolbox">
                    <div className="inactive">
                        <ImageMenuIcons type={"done"} color={check_color} width="31" height="31" />
                    </div>
                    {renderQCbutton(image)}
                </div>
            );
        }
    }

    const renderQCbutton = (image) => {
        if (qc) {
            let value = undefined;
            if (qc.includes("quick") && image.accepted) {
                value = image.accepted === "no"? "bad" : "good";
            } else if (qc.includes("complete") && image.score) {
                value = image.score.General[0].value === "Yes"? "bad" : "good";
            }
            let qc_title = value? (value === "bad"? "Bad-quality image" : "Good-quality image") : "Quality not evaluated";
            let qc_icon = value? (value === "bad"? "thumbsdown-filled" : "thumbsup-filled") : "thumbsup";
            let qc_color = value? (value === "bad"? "#ff8383" : "#96ff83") : "#888";
            return (
                <div className={classNames({"inactive": validated})} title={qc_title} onClick={() => { if (!validated) { handleQCclick(image) } }}>
                    <ImageMenuIcons type={qc_icon} color={qc_color} width="31" height="31" />
                </div>
            );    
        }
    }

    const renderCommentIcon = (image) => {
        if (image.comments_review && image.comments_review.length > 0) {
            return (
                <React.Fragment>
                    <ImageMenuIcons type={"commentfull"} style={{ fill: "#FF5108" }} width="26" height="26" />
                    <div className="bubble">{image.comments_review.length}</div>
                </React.Fragment>
            );
        } else {
            return <ImageMenuIcons type={"comment"} style={{ fill: "#FF5108" }} width="26" height="26" />
        }
    }

    // RENDER FLOATING VIEWER
    const renderFloatingViewer = () => {
        let style = { padding: "2px", background: "black", left: "5vw", top: "3vh", width: "90vw", height: "85vh", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", position: "absolute", zIndex: "100", }
        if (image_to_edit && edit_option) {
            return (
                <div className="grid-block" style={{ position: "absolute", width: "100%", height: "100%", background: "#060606c2", overflow: "hidden" }}>
                    <div className="grid-block" style={{ display: "flex", flexDirection: "row-reverse", marginRight: "50px", marginTop: "20px" }}>
                        <div className="grid-block shrink" style={{ height: "40px", width: "40px", cursor: "pointer", zIndex: "1000" }}
                            onClick={() => { on_action_to_perform("VALIDATION", { action: "CLOSE-EDITING", value: { image: image_to_edit } }) }}>
                            <CancelOutlinedIcon fontSize="large" sx={{ fontSize: "40px" }} />
                        </div>
                    </div>
                    {renderFloatingDivContent(image_to_edit, edit_option, style)}
                </div>
            );
        }
    }

    const renderFloatingDivContent = (image_to_edit, edit_option, style) => {
        const modalities = HelperConfig.getImageModalities(HelperStudies.getDataType());
        const views = HelperConfig.getImageViews(HelperStudies.getDataType());
        const image_types = HelperConfig.getImageTypes({ data_type: HelperStudies.getDataType() });

        if (["cycletiming", "segmentation"].includes(edit_option)) {
            return (
                <div className="grid-block validation-edit" style={style}>
                    <DicomViewerComponent
                        site={edit_option}
                        image_to_show={image_to_edit}
                        play_multiframe={true}
                        play_by_default={false}
                        show_player_controls={edit_option === "cycletiming"}
                    />
                </div>
            );
        } else if (edit_option === "qc") {
            return (
                <div className="grid-block validation-edit" style={style}>
                    <ScoreComponent
                        image={image_to_edit}
                        image_analysis={image_to_edit}
                        user_type={HelperUser.getUserType()}
                    />
                </div>
            );
        } else if (edit_option === "comments") {
            return (
                <div className="grid-block validation-edit" style={style}>
                    <ChatComponent
                        image={image_to_edit}
                        attribute={"comments_review"}
                    />
                </div>
            );
        } else if (edit_option === "label") {
            return (
                <div className="selection-component-vali validation-edit" >
                    <TopBarComponent
                        image={image_to_edit}
                        image_analysis={image_to_edit}
                        image_types={image_types}
                        views={views}
                        modalities={modalities}
                        on_action_to_perform={onActionToPerformLabel}
                    />
                    <div className="grid-block" style={{ alignItems: 'center', alignContent: 'center', flexDirection: 'column' }}>
                        <DicomViewerComponent
                            site={"selection"}
                            image_to_show={image_to_edit}
                            play_multiframe={true}
                            play_by_default={false}
                            show_player_controls={false}
                        />
                    </div>
                </div>
            );
        }
    }

    // RENDER VALIDATION BUTTONS
    const renderFinishReview = () => {
        let state = HelperStudies.getStudyState();
        if (state !== "Incomplete" || !show_study_state) {
            return (
                <div className="validate-button study" onClick={() => { on_action_to_perform("VALIDATION", { action: "VALIDATE-ANALYSIS", value: { image: false } }) }}>
                    <div>
                        <GeneralIcons type={"thumbsup"} color={"#3FCA1C"} width="36" height="36" />
                    </div>
                    <div>Finish review</div>
                </div>
            );
        }
    }
    const renderValidateStudyButton = () => {
        if (!images_after && !validated) {
            return (
                <>
                    <div className="validate-button control" onClick={() => { on_action_to_perform("VALIDATION", { action: "ADD-CHECK-POINT", value: { image: false, value: 1 } }) }}>
                        <div>
                            <GeneralIcons type={"eye"} color={"#DD7700"} width="40" height="40" />
                        </div>
                        <div>Check point</div>
                    </div>
                    {renderFinishReview()}
                </>
            );
        }
    }

    return (
        <>
            <div className="validation-banner">
                {renderFinishedNotice()}
                {renderStudyState()}
            </div>
            <div className="validation-component">
                {renderPreviousIcon()}
                {renderValidationComp()}
                {renderNextIcon()}
                {renderValidateStudyButton()}
                {renderFloatingViewer()}
            </div>
        </>
    );
}