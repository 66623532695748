export const DataProvider_CLINICAL = (uri, type, parameters, token) => {
    let options = false;
    let url = false;
    let response_type = "json";

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-api-key', token);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    switch (type) {
        case "GET_PATIENT_IDS_LIST":
            if ("patient_list" in parameters) {
                //create url
                url = uri + "/patients/get_institution_id_from_list"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;

        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    if (options !== false && url !== false) {
        let request = new Request(url, options);
        return fetch(request).then((response) => {
            if (response.status === 200) {

                if (response_type === "json") {

                    return response.json();

                } else if (response_type === "blob") {

                    return response.blob();

                } else if (response_type === "image") {

                    let blob = response.blob();
                    return URL.createObjectURL(blob);

                }

            } else {

                if (response.statusText === "UNAUTHORIZED") {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                } else {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                }
            }

        }).catch((error) => {
            console.error('Error:', error);
        });;

    } else {
        throw new Error(`Unsupported Data Provider request parameters ${parameters}`);
    }
}