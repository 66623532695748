import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

// Components
import { PlatformNameComponent } from "../platform_name/platform_name_component";
import { ToolboxUser } from './toolbox_user/toolbox_user';
import { ToolboxUserIcons } from './toolbox_user/toolbox_user_icons';
import { useStyles } from './toolbox_styles';

// Helpers
import { HelperUser } from "../../../helpers/helper_user/helper_user";
import { HelperConfig } from "../../../helpers/helper_config/helper_config";

// AUX functions
import { isObjectEmpty } from "../../../modules/object_module";

export const HeaderComponent = (props) => {
    const possibleTabs = HelperConfig.getTabs(props.user.user_type,props.mode)
    let user = props.user;
    const [open, setOpen] = React.useState(false);
    
    let user_name_abr = HelperUser.getNameAbreviation(user);
    const classes = useStyles();
    const renderTab = (tab) => {
        let style = classes.tab;
        if (tab.url === props.mode) {
            style = classes.currentTab;
        }
        let value = { 
            page: tab.url,
            id_study: props.study.study_id,
            id_patient: props.study.patient_id,
            project: props.study.currentproject,
            type: props.study.data_type
        };
        if (props.study?.editor) {
            value.editor = props.study.editor;
        }
        return (
            <div key={tab.url} className={"grid-frame " + style} onClick={() => { if (props.active) { props.on_action_to_perform("GENERAL",
            { action: "GO_TO_PAGE", value }); } }}>
                {tab.name2show}
            </div>
        )
    }
    const renderTabs = () => {
        if (possibleTabs.length > 0) {
            return possibleTabs.map((tab) => {
                if (tab.name2show) {
                    return renderTab(tab);
                }
            });
        }
    }
    const renderStudiesModes = () => {
        if (props.mode === "studies") {
            var style_list = {borderRadius:"100px",outlineOffset:"7px"};
            var style_stats = {borderRadius:"100px",outlineOffset:"7px"};
            if (props.studies_tab === "stats") {
                style_stats.outline = "darkred 2px solid";
            } else {
                style_list.outline = "darkred 2px solid";
            }
            return (
                <div style={{display:"flex",alignItems:"center",gridGap:"50px"}}>
                    <Tooltip title="List of patients" placement="bottom">
                        <div style={style_list} onClick={() => props.studies_tab_change("list")}><ToolboxUserIcons icon="list" /></div>
                    </Tooltip>
                    <Tooltip title="Project statistics" placement="bottom">
                        <div style={style_stats} onClick={() => props.studies_tab_change("stats")}><ToolboxUserIcons icon="stats" /></div>
                    </Tooltip>
                </div>
            );
        }
    }
    const renderComment = () => {
        var mode = props.mode;
        let action = ""
        if (mode !== "studies" && mode !== "review" && mode !== "patients" && mode !== "image-info" && mode.length !== 0) {
            action = "HANDLE-COMMENT-DIALOG";

        } else if (mode === "studies") {
            action = "HANDLE-COMMENT-PAGE";
        }
        if (action) {
            return (
                <Tooltip title="Add comment to study" placement="bottom">
                    <div className={"grid-block " + classes.commentIconDiv}
                        onClick={props.on_action_to_perform({ action: action, value: "" })}
                    >
                        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                            <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                        </svg>
                    </div>
                </Tooltip>
            )
        }
    }
    const handleUserToolbox = (state) =>{
        setOpen(state);
    }
    const renderUserIcon = () => {
        return (
            <div className="grid-block "
                onMouseEnter={function(){handleUserToolbox(true);}}
                onMouseLeave={function(){handleUserToolbox(false);}}
                onMouseOver={function(){handleUserToolbox(true);}}
                onClick={function(){handleUserToolbox(true);}}
                style={{ maxWidth: "53px", marginRight: "10px", overflow: "hidden" }}
            >
                <div className={"grid-block shrink " + classes.headerSeparator} >&nbsp;</div>
                <div className={"grid-block shrink align-center " + classes.userIcon}>
                    <div className={"grid-block shrink " + classes.userAbbr} >{user_name_abr}</div>
                </div>
            </div>
        );
    }
    const renderUserToolbox = () => {
        if (!isObjectEmpty(user)) {
            return <ToolboxUser handleUserToolbox={handleUserToolbox} user={user} on_action_to_perform={props.on_action_to_perform} open={open}/>
        }
    }
    const renderPlatformVersion = (v) => {
        return (
            <div style={{ padding: "21px", fontSize: "12px", fontStyle: "italic" }}>v. {v}</div>
        )
    }

    let title = "Image Analysis cPlatform";
    if (props.study?.patient_id_show && props.study?.study_date) {
        title = "patient " + props.study.patient_id_show + "  date " + props.study.study_date;
        if (props.mode === "validation") {
            title = title + "  by " + props.study.editor;
        }
    }

    return (
        <div className="">
            <div className={"grid-block horizontal " + classes.header}>
                <PlatformNameComponent
                    site={"inline"}
                    title={title}
                    on_action_to_perform={props.on_action_to_perform}
                />
                <div className="grid-block horizontal shrink">
                    {renderTabs()}
                </div>
                <div className="grid-block">&nbsp;</div>
                {/* {renderComment()} */}
                {renderStudiesModes()}
                <div className="grid-block">&nbsp;</div>
                {renderPlatformVersion("2024-11-11")}
                {renderUserIcon()}
            </div>
            {renderUserToolbox()}
        </div>
    )

}