import { findWhere } from 'underscore';
import { Point, LineSegment, Line, Polygon } from 'ts-2d-geometry';
import { Polygon as Polygon2 } from '@flatten-js/core';
import { meanValue, euclideanDistance } from './math_module';
import { getSplineRealValues } from './spline_module';
import { getDopplerRegion } from './dicom_module';

export function getThickness(shapes, cardiac_event, segmentation, zero_line, image) {
    const mean_thickness_array = [];
    const traces = []
    segmentation.map((cc, id) => {
        const cc_event = cc[cardiac_event];
        if (cc_event) {
            const shape1 = findWhere(cc_event.lines, { name: shapes[0] });
            const shape2 = findWhere(cc_event.lines, { name: shapes[1] });
            if (shape1 && shape2) {
                const s1_points = getSplineRealValues(shape1.points, zero_line, image, "B-mode Y");
                const s2_points = getSplineRealValues(shape2.points, zero_line, image, "B-mode Y");
                const thickness = calculateDistanceBetweenLines(s1_points, s2_points);
                traces.push({
                    points: thickness,
                    name: cardiac_event + "_" + String(id)
                });
                mean_thickness_array.push(meanValue(thickness));
            }
        }
    })
    return {
        mean: mean_thickness_array,
        all: traces
    };
}

function calculateDistanceBetweenLines(s1, s2) {
    let th = []
    s1.map((p1, cont) => {
        let distances = [];
        s2.map((p2, id) => {
            distances.push(euclideanDistance(p1, p2))
        })
        th.push(Math.min(...distances))
    })
    return th;
}


export function calculateAreaLengthVolumes(shape, cardiac_event, segmentation, zero_line, image) {
    const volumes = [];
    segmentation.map((cc) => {
        const cc_event = cc[cardiac_event];
        if (cc_event) {
            const line = findWhere(cc_event.lines, { name: shape });
            if (line && line.points.length > 2) {
                // calculate cavity volume
                const v = areaLengthVolume(line.points, zero_line, image);
                volumes.push(v)
            }
        }
    });
    return volumes;
}

export function calculateAreas(shape, cardiac_event, segmentation, image) {
    const areas = [];
    segmentation.map((cc) => {
        const cc_event = cardiac_event? cc[cardiac_event] : (cc.lines? cc : false);
        if (cc_event) {
            const line = findWhere(cc_event.lines, { name: shape[0] });
            if (line) {
                // calculate cavity area
                let real_points = getSplineRealValues(line.points, "", image, "B-mode Y");
                let polygon = new Polygon2(real_points.map(p => [p.x,p.y]));
                areas.push(polygon.area());
            }
        }
    });
    return areas;
}

export function anglesFromPoints(points) {
    let angles = [];
    if (points.length === 4) {
        const line1 = LineSegment.fromValues(points[0].x, points[0].y, points[1].x, points[1].y).asLine();
        const line2 = LineSegment.fromValues(points[2].x, points[2].y, points[3].x, points[3].y).asLine();
        const center = line1.intersect(line2).value;
        const a1 = Math.atan2(points[0].y - center.y, points[0].x - center.x),
            a2 = Math.atan2(points[2].y - center.y, points[2].x - center.x);
        angles.push((Math.max(a1,a2)-Math.min(a1,a2))*(180/Math.PI));
        angles.push((360-2*angles[0])/2);
    }
    return angles;
}

export function pointIsBetweenPoints(point, a, b) {
    let x_between = (point.x >= a.x && point.x <= b.x) || (point.x <= a.x && point.x >= b.x);
    let y_between = (point.y >= a.y && point.y <= b.y) || (point.y <= a.y && point.y >= b.y);
    return x_between && y_between;
}

function areaLengthVolume(cps, zero_line, image_from_store) {
    let real_points = getSplineRealValues(cps, zero_line, image_from_store, "B-mode Y")
    if (real_points.length > 0) {
        let centers = getCenters(real_points, cps.length, "B");
        let L = getLength(centers);
        // let A = getArea(real_points);
        let polygon = new Polygon2(real_points.map(p => [p.x,p.y]));
        let V = (0.85 * Math.pow(polygon.area(), 2)) / L;
        return V;
    }
    return 0;
}

function getCenters(real_points, N, method) {
    let centers = [];

    let mitral_free_wall = real_points[real_points.length - 1];
    let mitral_septal = real_points[0];
    let mitral_center = {
        x: (mitral_free_wall.x + mitral_septal.x) / 2,
        y: (mitral_free_wall.y + mitral_septal.y) / 2
    };
    centers.push(mitral_center);

    // A) DIVIDE POINTS IN TWO PARTS AND SEARCH THE MINIMUM DISTANCE POINT FROM PART 2 FOR EACH POINT IN PART 1
    // sensitive to uneven distribution of points / weird ventricle shapes
    if (method === "A") {
        for (var i = 0; i < parseInt(real_points.length / 2 - 1); i++) {
            let p1 = real_points[i];
            let distances = [];
            for (var j = parseInt(real_points.length / 2); j < real_points.length; j++) {
                let p2 = real_points[j];
                if (p1 && p2) {
                    let d = p2.x - p1.x;
                    distances.push(d);
                }
            }
            let min_id = distances.indexOf(Math.min(...distances)) + parseInt(N / 2);
            let mean_point = {
                x: (p1.x + real_points[min_id].x) / 2,
                y: (p1.y + real_points[min_id].y) / 2
            }
            centers.push(mean_point);
        }
    
    // B) GET JUST MITRAL CENTER AND APPEX (POINT WITH FURTHEST "Y")
    // sensitive to ventricle rotation
    } else if (method === "B") {
        let distances = [];
        for (var i = 0; i < parseInt(real_points.length - 1); i++) {
            distances.push(Math.abs(mitral_center.y - real_points[i].y));
        }
        let max_id = distances.indexOf(Math.max(...distances));
        centers.push(real_points[max_id]);

    // C) GET JUST MITRAL CENTER AND APPEX (POINT AT MAX DISTANCE)
    // sensitive to weird ventricle shapes
    } else if (method === "C") {
        let distances = [];
        for (var i = 0; i < parseInt(real_points.length - 1); i++) {
            distances.push(euclideanDistance(mitral_center, real_points[i]));
        }
        let max_id = distances.indexOf(Math.max(...distances));
        centers.push(real_points[max_id]);
    }

    return centers;
}

function getLength(points) {
    let L = 0;
    points.map((p,i) => {
        if (i !== points.length-1) {
            L = L + euclideanDistance(p,points[i+1]);
        }
    });
    return L;
}

export function getPhysicalDeltas(image) {
    let flow_region = false;
    let physical_delta_x = 0;
    let physical_delta_y = 0;
    if (image.metadata?.flow_region) {
        physical_delta_x = image.metadata.flow_region.spacing_x;
        physical_delta_y = image.metadata.flow_region.spacing_y;
    } else if (image.metadata?.doppler_region) {
        physical_delta_x = image.metadata.doppler_region.spacing_x;
        physical_delta_y = image.metadata.doppler_region.spacing_y;
    } else {
        flow_region = getDopplerRegion(image.dicom.metadata.doppler_regions, image.modality);
        physical_delta_x = flow_region.physical_delta_x;
        physical_delta_y = flow_region.physical_delta_y;
    }
    return [ physical_delta_x, physical_delta_y ];
}

export function fromPixelsToRealArray(array, y_mode, zero_line, image) {
    let real = array.map(p => {
        let new_p = ({ ...p });
        new_p.x = fromPixelsToReal(p.x, "X", zero_line, image);
        new_p.y = fromPixelsToReal(p.y, y_mode, zero_line, image);
        return new_p;
    });
    return real;
}

export function fromPixelsToReal(value, axis, zero_line, image) {
    let [ physical_delta_x, physical_delta_y ] = getPhysicalDeltas(image);
    if (axis === "X") {
        return value * physical_delta_x;
    } else if (axis === "Y") {
        return (zero_line - value) * physical_delta_y;
    } else if (axis === "B-mode Y") {
        return value * physical_delta_y;
    }
    return false;
}

// export function getPointsAtGivenDistanceAndSlope(point, dist, slope) {
//     let a = {};
//     let b = {};
//     if (slope === 0) {
//         a.x = point.x + dist;
//         a.y = point.y;
//         b.x = point.x - dist;
//         b.y = point.y;
//     } else if (!isFinite(slope)) {
//         a.x = point.x;
//         a.y = point.y + dist;
//         b.x = point.x;
//         b.y = point.y - dist;
//     } else {
//         var dx = (dist / Math.sqrt(1 + (slope * slope)));
//         var dy = slope * dx;
//         a.x = point.x + dx;
//         a.y = point.y + dy;
//         b.x = point.x - dx;
//         b.y = point.y - dy;
//     }
//     return { a, b };
// }

export function relocateEpiPoints(epi, endo) {
    try {
        let epi_pol = Polygon.fromPoints(epi.map(point => new Point(point.x, point.y)));
        let new_epi = [];
        let points_to_remove = [];
        endo.map((point,i) => {
            if (i === 0 || i === endo.length-1) {
                let original = findWhere(epi, { type: point.type });
                new_epi.push({
                    id: point.id - points_to_remove.length,
                    x: original.x,
                    y: original.y,
                    type: point.type
                });
            } else if (i > 0 && i < endo.length-1) {
                let a = LineSegment.fromValues(endo[i].x, endo[i].y, endo[i-1].x, endo[i-1].y).asVector().normed();
                let b = LineSegment.fromValues(endo[i].x, endo[i].y, endo[i+1].x, endo[i+1].y).asVector().normed();
                let sum = a.plus(b);
                sum = sum.isNullVector()? a.clockwisePerpendicular() : sum;
                let endo_point = new Point(point.x, point.y);
                let ortho = new Line(endo_point, sum);
                let intersects = epi_pol.lineSegments.map(segment => segment.intersectForLine(ortho)).filter(inter => inter.value);
                let epi_point = closestPoint(endo_point, intersects);
                if (epi_pol.lineSegments[epi.length-1].containsPoint(epi_point)) {
                    points_to_remove.push(i);
                } else {
                    new_epi.push({
                        id: point.id - points_to_remove.length,
                        x: epi_point.x,
                        y: epi_point.y,
                        type: "user-defined_" + (point.id - points_to_remove.length)
                    });
                }
            }
        });
        let new_endo = endo.filter(point => !points_to_remove.includes(point.id));
        new_endo = new_endo.map((point,i) => {
            point.id = i;
            if (point.type.includes("user-defined")) {
                point.type = "user-defined_" + i;
            }
            return point;
        });
        return { new_epi, new_endo };
    } catch (error) {
        console.log("Relocating Epi points was not possible");
        return { new_epi: epi, new_endo: endo };
    }
}

function closestPoint(point, list) {
    let distances = list.map(list_point => point.distanceSquare(list_point.value));
    let ind = distances.indexOf(Math.min(...distances));
    return list[ind].value;
}