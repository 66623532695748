import { DataProvider_ADMIN } from "./dataprovider_admin";
import { DataProvider_JOBS } from "./dataprovider_jobs";
import { DataProvider_CLINICAL } from "./dataprovider_clinical";
import { DataProvider_MINIO } from "./dataprovider_minio";
import { DataProvider_MONGO } from "./dataprovider_mongo";
import { HelperConfig } from "../helpers/helper_config/helper_config";

export const DataProvider = (providerType, type, params, token) => {
    let data = false;
    let api_config = HelperConfig.getAPIConfig();
    let api_admin_uri = api_config.api_admin_uri;
    let api_jobs_uri = api_config.api_jobs_uri;
    let api_custom_uri = api_config.api_custom_uri;
    let api_cd_uri = api_config.api_cd_uri;

    switch (providerType) {
        case "MONGO":
            data = DataProvider_MONGO(api_custom_uri, type, params, token);
            break;
        case "MINIO":
            data = DataProvider_MINIO(api_custom_uri, type, params, token);
            break;
        case "CLINICAL":
            data = DataProvider_CLINICAL(api_cd_uri, type, params, token);
            break;
        case "ADMIN":
            data = DataProvider_ADMIN(api_admin_uri, type, params, token);
            break;
        case "JOBS":
            data = DataProvider_JOBS(api_jobs_uri, type, params, token);
            break;
        default:
            break;
    }

    return data;

}