import React, { useState, useEffect } from 'react';

import { Pie } from "react-chartjs-2";
import Input from "@material-ui/core/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Helpers
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

export const QualityStatistics = ({ 
        project,
        show,
        handle_header
    }) => {

    const [scores, setScores] = useState(false);
    const [stats, setStats] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dateFilter, setDateFilter] = useState(false);
    const [gaFilter, setGaFilter] = useState({ from: "", to: "" });

    useEffect(() => {
        if (show) {
            loadScores();
        }
    }, [project]);

    useEffect(() => {
        if (show && !stats) {
            loadScores();
        }
    }, [show]);

    useEffect(() => {
        if (scores) {
            let filter_scores = HelperStudies.getProjectIQScoresByDate(scores, dateFilter);
            filter_scores = HelperStudies.getProjectIQScoresByGA(filter_scores, gaFilter);
            setStats(HelperStudies.getProjectIQStats(filter_scores));
            setLoading(false);
        }
    }, [scores, dateFilter, gaFilter]);

    const loadScores = () => {
        setLoading(true);
        setStats(false);
        HelperStudies.getProjectIQScores().then((result) => {
            HelperStudies.getStudies().then(() => {
                setScores(result);
                if (result === false) {
                    setLoading(false);
                }
            });
        });
    }

    const getPercentage = (value) => {
        return value? value.toFixed(2) + " %" : "--";
    }

    const handleTitleClick = () => {
        if (show) {
            handle_header(false);
        } else {
            handle_header("quality");
        }
    }

    const renderGAFilter = () => {
        if (show && HelperConfig.getProjectQCConfig().ga_filter) {
            return (
                <div className="filter-box">
                    <span><b>GA</b> from</span>
                    <div className="date-picker">
                        <Input
                            placeholder="Type weeks"
                            value={gaFilter.from}
                            onChange={(event) => setGaFilter({ from: event.target.value, to: gaFilter.to })}
                        />
                    </div>
                    <span>to</span>
                    <div className="date-picker">
                        <Input
                            placeholder="Type weeks"
                            value={gaFilter.to}
                            onChange={(event) => setGaFilter({ from: gaFilter.from, to: event.target.value })}
                        />
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    const renderDateFilter = () => {
        if (show) {
            return (
                <div className="filter-box">
                    <span><b>Date</b> from</span>
                    <div className="date-picker">
                        <DatePicker
                            selected={dateFilter.from}
                            onChange={(date) => setDateFilter({ from: date, to: dateFilter.to })}
                            maxDate={dateFilter.to? dateFilter.to : new Date()}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select date"
                        />
                    </div>
                    <span>to</span>
                    <div className="date-picker">
                        <DatePicker
                            selected={dateFilter.to}
                            onChange={(date) => setDateFilter({ from: dateFilter.from, to: date })}
                            minDate={dateFilter.from}
                            maxDate={new Date()}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select date"
                        />
                    </div>
                </div>
            );   
        } else {
            return <div></div>;
        }
    }

    const renderContent = () => {
        if (show) {
            if (stats) {
                return renderIQStats();
            } else if (loading) {
                return <div style={{ marginTop: "20px" }}>Loading statistics...</div>;
            } else {
                return <div style={{ marginTop: "20px" }}>There are no statistics for this project yet.</div>;
            }
        }
    }

    const renderIQStats = () => {
        return (
            <div className="block-content quality">
                <div className="row">
                    <div>Assessed studies: <span>{stats.general.total_studies}</span></div>
                    <div>Average image acceptance per study: <span>{getPercentage(stats.general.average_acceptance)}</span></div>
                    <div className="quick-qc" style={{ textAlign: "center" }}>Quick QC studies: <span>{stats.general.quick_total}</span></div>
                    <div className="quick-qc">Average acceptance per study: <span>{getPercentage(stats.general.quick_acceptance)}</span></div>
                </div>
                {renderIQImageTypes()}
            </div>
        );
    }

    const renderIQImageTypes = () => {
        const sorted_imtypes = Object.keys(stats.image_types).sort((a,b) => a.localeCompare(b, undefined, {sensitivity: 'base'})).reverse();
        return sorted_imtypes.map(type => {
            const imtype = stats.image_types[type];
            return (
                <div className="row" key={type}>
                    <div>
                        <div><span>{type}</span> (<span>{imtype.total}</span> images)</div>
                        <div className="quick-qc per-image">
                            <div>Quick QC: (<span>{imtype.quick_total}</span> images)</div>
                            <div style={{ marginTop: "20px", width: "65%" }}>
                                <Pie
                                    options={{
                                        legend: { display: false },
                                        tooltips: {
                                            callbacks: {
                                                label: (tooltipItem, data) => {
                                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                                    const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                    const percentage = parseFloat((dataset.data[tooltipItem.index]/meta.total*100).toFixed(1));
                                                    return data.labels[tooltipItem.index] + ': ' + percentage + '%';
                                                }
                                            }
                                        }
                                    }}
                                    data={{
                                        labels: ["Accepted", "Rejected"],
                                        datasets: [{
                                            data: [imtype.quick_accepted, imtype.quick_total-imtype.quick_accepted],
                                            backgroundColor: ["#0a0", "#b00"]
                                        }]
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {renderStatsSections(imtype)}
                </div>
            );
        });
    }

    const renderStatsSections = (imtype) => {
        if (imtype.total > 0) {
            return Object.keys(imtype.sections).map(section => {
                return (
                    <div className="column" key={section}>
                        <span>{section}</span>
                        {renderSectionContent(imtype.sections[section])}
                    </div>
                );
            });    
        }
    }

    const renderSectionContent = (section) => {
        return Object.keys(section).map(point => {
            const labels = Object.keys(section[point]);
            // Here we work with colors in the HSL space to get better interpolations
            let cfrom = [120,100,25], cto = [0,100,40], n = labels.length-2;
            const datasets = [{
                data: Object.values(section[point]),
                backgroundColor: labels.map((label, i) => {
                    if (label === "N/A") {
                        return "#888";
                    } else {
                        return "hsl("+(cfrom[0]+(cto[0]-cfrom[0])*i/n)+"deg,"+(cfrom[1]+(cto[1]-cfrom[1])*i/n)+"%,"+(cfrom[2]+(cto[2]-cfrom[2])*i/n)+"%)";
                    }
                }) 
            }];
            return (
                <div key={point}>
                    <div className="point-title">{point}</div>
                    <div>
                        <Pie
                            options={{
                                legend: { display: false },
                                tooltips: {
                                    callbacks: {
                                        label: (tooltipItem, data) => {
                                            const dataset = data.datasets[tooltipItem.datasetIndex];
                                            const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                            const percentage = parseFloat((dataset.data[tooltipItem.index]/meta.total*100).toFixed(1));
                                            return data.labels[tooltipItem.index] + ': ' + percentage + '%';
                                        }
                                    }
                                }
                            }}
                            data={{
                                labels: labels,
                                datasets: datasets
                            }}
                        />
                    </div>
                </div>
            );
        });
    }
    
    return (
        <>
            <div className="block-header">
                <div className="block-title" onClick={() => handleTitleClick()}>IMAGE QUALITY STATISTICS {show? '\u25B2' : '\u25BC'}</div>
                {renderGAFilter()}
                {renderDateFilter()}
            </div>
            {renderContent()}
        </>
    );
}