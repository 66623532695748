import React, { useState } from 'react';

// Components
import { ImageMetadataComponent } from './image_metadata_component';
import { HelpMenuComponent } from './help_menu_component';
import { ImageMenuIcons } from './../icons_component/image_menu_icons';
import { PatternIcons } from './../icons_component/pattern_icons';
import { GeneralIcons } from './../icons_component/general_icons';

// Helpers
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// AUX functions
import classNames from 'classnames';
import Slider from '@material-ui/core/Slider';
import { capitalizeFirstLetter } from '../../../modules/string_module';

export const ImageMenuComponent = ({
        site,
        image,
        image_analysis,
        current_action,
        state,
        on_action_to_perform,
        show_floating_window
    }) => {

    const [showMetadata, setShowMetadata] = useState(false);

    const renderReference = () => {
        if (site === "selection") {
            if (image_analysis?.reference) {
                return (
                    <div title="Unmark as reference" onClick={() => { on_action_to_perform({ action: "UNMARK-AS-REFERENCE" }) }}>
                        <ImageMenuIcons type={"checked"} className="active" />
                    </div>
                );
            } else {
                return (
                    <div title="Mark as reference" onClick={() => { on_action_to_perform({ action: "MARK-AS-REFERENCE" }) }}>
                        <ImageMenuIcons type={"unchecked"} className="active" />
                    </div>
                );
            }
        }
    }

    const renderDone = () => {
        if (["cycletiming","segmentation"].includes(site) && image_analysis) {
            const active = !["finished","unactive","caliper"].includes(current_action);
            return (
                <div title="Finish" onClick={() => { if (active) { on_action_to_perform({ action: "FINISH", value: { image: image_analysis }}) } }}>
                    <ImageMenuIcons type={"done"} className={classNames({"active":active})} width="31" height="31" />
                </div>
            );
        }
    }

    const renderCycleTiming2D = () => {
        // SET END DIASTOLE, SET END SYSTOLE
        if (site === "cycletiming" && image_analysis?.modality === "2D") {
            const active_diast = current_action === "annotating-diastole";
            const active_syst = current_action === "annotating-systole";
            return (
                <React.Fragment>
                    <div title="Mark END DIASTOLE" className={classNames({"active":active_diast},"text")} onClick={() => { if (active_diast) { on_action_to_perform({ action: "SELECT-ENDDIASTOLE", value: { image: image_analysis }}) } }}>
                        ED
                    </div>
                    <div title="Mark END SYSTOLE" className={classNames({"active":active_syst},"text")} onClick={() => { if (active_syst) { on_action_to_perform({ action: "SELECT-ENDSYSTOLE", value: { image: image_analysis }}) } }}>
                        ES
                    </div>
                </React.Fragment>
            );
        }
    }

    const renderAliasingCorrection = () => {
        // Doppler correct visualization of images with aliasing
        if (site === "segmentation" && image_analysis?.modality.includes("Doppler")) {
            const up = state.correct_aliasing? state.correct_aliasing.up : 0;
            const down = state.correct_aliasing? state.correct_aliasing.down : 0;
            return (
                <div title="Correct aliasing" id="correction" className="focusable">
                    <ImageMenuIcons type={"wave"} className="active" width="34px" />
                    <div className="correction-box">
                            Correct upper crop
                            <div style={{marginBottom:"20px"}}>
                                <Slider
                                    value={up}
                                    min={-2}
                                    step={1}
                                    max={200}
                                    onChange={(e, val) => { on_action_to_perform({ action: "CORRECT-ALIASING-PARAMS", value: { image: image_analysis, up: val }}) }}
                                    aria-labelledby="continuous-slider"
                                />
                            </div>
                            Correct bottom crop
                            <div>
                                <Slider
                                    value={down}
                                    min={-2}
                                    step={1}
                                    max={200}
                                    onChange={(e, val) => { on_action_to_perform({ action: "CORRECT-ALIASING-PARAMS", value: { image: image_analysis, down: val }}) }}
                                    aria-labelledby="continuous-slider"
                                />
                            </div>
                    </div>
                </div>
            );
        }
    }

    const renderRemoveLastCC = () => {
        // Doppler remove last CC from segmentation
        if (site === "segmentation" && image_analysis?.modality.includes("Doppler")) {
            let active = state.current_action !== "selecting-cycles" && image_analysis?.cardiac_events.length > 1;
            return (
                <div title="Remove last CC" onClick={() => { on_action_to_perform({ action: "REMOVE-LAST-CC", value: { image: image_analysis }}) }}>
                    <ImageMenuIcons type={"removecc"} className={classNames({"active":active})} width="28px" />
                </div>
            );
        }
    }

    const renderPatterns = () => {
        if (site === "segmentation" && image_analysis?.modality.includes("Doppler") && state.current_cycle >= 0) {
            const patterns = HelperConfig.getPossiblePatterns(image_analysis.type, image_analysis.modality);
            if (patterns.length > 1 && image_analysis.segmentation.length > 0) {
                const active =  current_action.includes("segment");
                if (image_analysis.segmentation[state.current_cycle]) {
                    const pattern = image_analysis.segmentation[state.current_cycle].lines[0].pattern;
                    return (
                        <div title="Choose pattern" id="pattern" className={classNames({"focusable":active})}>
                            <PatternIcons type={pattern} className={classNames({"active":active},"text")} width={"35px"} height={"35px"} />
                            <div className="pattern-box">
                                {patterns.map(p => {
                                    return (
                                        <div key={p.type} title={capitalizeFirstLetter(p.type)} onClick={() => { on_action_to_perform({ action: "CHANGE-PATTERN", value: { image: image_analysis, pattern: p }}) }}>
                                            <PatternIcons type={p.type} className={classNames({"active":active},"text")} width={"35px"} height={"35px"} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                }
            }
        }
    }

    const renderPrediction = () => {
        if (site === "segmentation" && image_analysis?.modality === "2D" && image_analysis?.cardiac_events.length > 0) {
            let active = current_action === "segmenting" && state.current_cycle > 0;
            return (
                <div title="Predict from previous CC" onClick={() => { if (active) { on_action_to_perform({ action: "PREDICT-FROM-PREVIOUS", value: { image: image_analysis }}) } }}>
                    <ImageMenuIcons type={"magic"} className={classNames({"active":active})} width="28" height="28" />
                </div>
            );
        } else if (site === "segmentation" && image_analysis?.modality.includes("Doppler")) {
            let type = state.predict_cycles? "nomagic" : "magic";
            let active = state.current_action !== "selecting-cycles";
            return (
                <div title="Predict next cycles" onClick={() => { on_action_to_perform({ action: "TOGGLE-PREDICT-CYCLES", value: { image: image_analysis }}) }}>
                    <ImageMenuIcons type={type} className={classNames({"active":active})} width="28" height="28" />
                </div>
            );
        }
    }

    const renderBasicOptions = () => {
        // UNDO, EDIT, REMOVE
        let active_undo = false; let active_edit = false; let active_remove = false;
        if (current_action !== "caliper") {
            active_undo = !["finished","unactive","editing","editing-thickness","editing-segmentation","selecting-cycles"].includes(current_action);
            active_edit = current_action==="finished" && !(image_analysis.modality === "2D" && site === "cycletiming");
            active_remove = !["unactive","selecting-cycles"].includes(current_action);    
        }
        if (["cycletiming","segmentation"].includes(site) && image_analysis) {
            return (
                <React.Fragment>
                    <div title="Undo" onClick={() => { if (active_undo) { on_action_to_perform({ action: "UNDO", value: { image: image_analysis }}) } }}>
                        <ImageMenuIcons type={"undo"} className={classNames({"active":active_undo})} width={"28px"} />
                    </div>
                    <div title="Edit" onClick={() => { if (active_edit) { on_action_to_perform({ action: "EDIT", value: { image: image_analysis }}) } }}>
                        <ImageMenuIcons type={"edit"} className={classNames({"active":active_edit})} />
                    </div>
                    <div title="Remove all" onClick={() => { if (active_remove) { on_action_to_perform({ action: "REMOVE-ALL", value: { image: image_analysis }}) } }}>
                        <ImageMenuIcons type={"remove"} className={classNames({"active":active_remove})} width={"40px"} style={{ position: "relative", right: "5px" }}/>
                    </div>
                </React.Fragment>
            );
        }
    }

    const renderOnsetSelection = () => {
        if (site === "cycletiming") {
            const onset_options = HelperConfig.getToolConfig("cycletiming","onset_options");
            return (
                <div title="Onset selection" id="pattern" className="focusable">
                    <GeneralIcons type={"onset_"+image_analysis.onset_type} className="active text" width="35px" height="35px" style={{fontSize:"10px"}} />
                    <div className="pattern-box">
                        {onset_options.map(o => {
                            return (
                                <div key={o} title={o} onClick={() => { on_action_to_perform({ action: "CHANGE-ONSET-TYPE", value: { image: image_analysis, type: o }}) }}>
                                    <GeneralIcons type={"onset_"+o} className="active text" width="35px" height="35px" style={{fontSize:"10px"}} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    const renderCaliper = () => {
        if (site === "segmentation" && image_analysis?.modality === "2D") {
            let icon = state.current_action === "caliper"? "nomeasure" : "measure";
            let title = state.current_action === "caliper"? "Disable caliper tool" : "Enable caliper tool";
            return (
                <div title={title} onClick={() => { on_action_to_perform({ action: "TOGGLE-CALIPER", value: { image: image_analysis }}) }}>
                    <ImageMenuIcons type={icon} className={"active"} width={"31px"} height={"31px"} />
                </div>
            );
        }
    }

    const renderMeasurements = () => {
        if (site === "segmentation") {
            let action = state.show_measurements? "off" : "on";
            let icon = state.show_measurements? "noreport" : "report";
            let title = state.show_measurements? "Hide measurements" : "Show measurements";
            let active =  !["selecting-cycles"].includes(state.current_action);
            return (
                <div title={title} onClick={() => { on_action_to_perform({ action: "SHOW-MEASUREMENTS", value: { image: image_analysis, type: action }}) }}>
                    <ImageMenuIcons type={icon} className={classNames({"active":active})} width={"39px"} height={"39px"} />
                </div>
            );
        }
    }

    const renderCompleteQC = () => {
        if (HelperStudies.getCurrentProjectObj().qc?.type.includes("complete")) {
            const active = image_analysis?.type && image_analysis.reference && !image_analysis.score && HelperUser.getUserType() !== "Viewer";
            return (
                <div id="qc" className={classNames({"focusable":active})}>
                    <div title="Image quality check" onClick={() => { show_floating_window(true) }}>
                        <ImageMenuIcons type={"qualitycheck"} className="text active" style={{ border: "none", fontSize: "20px", fontWeight: "bold" }} />
                    </div>
                    <div className="qc-box">
                        <div title={'Evaluate as "All good"'} onClick={() => { on_action_to_perform({ action: "QC-ALL-GOOD" }) }}>
                            <ImageMenuIcons type={"done"} className="active" width="31" height="31" />
                        </div>
                    </div>
                </div>    
            );
        }
    }

    const renderQuickQC = () => {
        if (HelperStudies.getCurrentProjectObj().qc?.type.includes("quick")) {
            const active = image_analysis?.type && image_analysis.reference;
            const accept = image_analysis?.accepted === "yes"? "thumbsup-filled" : "thumbsup";
            const reject = image_analysis?.accepted === "no"? "thumbsdown-filled" : "thumbsdown";
            return (
                <>
                    <div title="Accept image" onClick={() => { on_action_to_perform({ action: "QC-ACCEPT", value: "yes" }) }}>
                        <ImageMenuIcons type={accept} className={classNames({"active-green":active})} width="28" height="28" />
                    </div>
                    <div title="Reject image" onClick={() => { on_action_to_perform({ action: "QC-ACCEPT", value: "no" }) }}>
                        <ImageMenuIcons type={reject} className={classNames({"active":active})} width="28" height="28" />
                    </div>
                </>
            );
        }
    }

    const renderImageOptions = () => {
        // DICOM DOWNLOAD, METADATA
        const active_img = image? image : image_analysis;
        return (
            <React.Fragment>
                <div title="Image options" id="image-options" className="focusable">
                    <ImageMenuIcons type="image" className="active" width="30px" height="30px" />
                    <div className="image-options-box">
                        <div title="Download DICOM" onClick={() => { window.location.href = active_img.urls.DICOM_url }}>
                            <ImageMenuIcons type={"download"} className="active" width="28px" height="28px" />
                        </div>
                        <div title="Image metadata" onClick={() => { setShowMetadata(true) }}>
                            <ImageMenuIcons type={"info"} className="active" width="32px" height="32px" style={{paddingTop:"1px",marginLeft:"-1px"}} />
                        </div>
                    </div>
                    <ImageMetadataComponent
                        image={active_img.dicom}
                        show={showMetadata}
                        set_show={setShowMetadata}
                    />
                </div>
                {renderImageExtras()}
            </React.Fragment>
        );
    }

    const renderImageExtras = () => {
        // COMMENTS, QC
        if (site === "selection") {
            return (
                <React.Fragment>
                    {renderCompleteQC()}
                    {renderQuickQC()}
                </React.Fragment>
            );    
        } else if (show_floating_window) {
            const hasComments = image_analysis?.comments? image_analysis.comments.filter(c => c.type === site).length : false;
            const bubble = hasComments? {} : { display:  "none" };
            const icon = hasComments? "commentfull" : "comment";
            return (
                <div title="Image comments" onClick={() => { show_floating_window(true) }}>
                    <ImageMenuIcons type={icon} className="active" width="30px" />
                    <div className="comment-bubble" style={bubble}>{hasComments}</div>
                </div>
            );    
        }
    }

    const renderConstraints = () => {
        if (site === "segmentation" && image_analysis?.modality.includes("Doppler")) {
            let action = state.constraints? "off" : "on";
            let icon = state.constraints? "unlock" : "lock";
            let title = state.constraints? "Remove constraints" : "Add constraints";
            let active = state.current_action !== "selecting-cycles";
            return (
                <div title={title} onClick={() => { on_action_to_perform({ action: "CONSTRAINTS", value: { image: image_analysis, type: action }}) }}>
                    <ImageMenuIcons type={icon} className={classNames({"active":active})} width={"30px"} />
                </div>
            );
        }            
    }

    const renderLegend = () => {
        if (site === "segmentation" && state.control_points && state.control_points.length > 0) {
            let action = state.show_legend? "off" : "on";
            let icon = state.show_legend? "nolegend" : "legend";
            let title = state.show_legend? "Hide legend" : "Show legend";
            let active = !["caliper","selecting-cycles"].includes(state.current_action);
            return (
                <div title={title} onClick={() => { on_action_to_perform({ action: "SHOW-LEGEND", value: { image: image_analysis, type: action }}) }}>
                    <ImageMenuIcons type={icon} className={classNames({"active":active})} width={"25px"} height={"25px"} />
                </div>
            );
        }
    }

    const renderHelp = () => {
        if (["cycletiming","segmentation"].includes(site) && image_analysis) {
            return (
                <React.Fragment>
                    <div id="help-button">
                        <ImageMenuIcons type={"help"} className="active" width="29px" />
                    </div>
                    <HelpMenuComponent
                        site={site}
                        modality={image_analysis.modality}
                    />
                </React.Fragment>
            );
        }
    }
    
    return image || image_analysis ? (
        <div className="image-menu">
            <div className="child">
                {renderReference()}
                {renderDone()}
                {renderCycleTiming2D()}
                {renderPatterns()}
                {renderAliasingCorrection()}
                {renderRemoveLastCC()}
                {renderPrediction()}
                {renderCaliper()}
                {renderMeasurements()}
                {renderBasicOptions()}
                {renderOnsetSelection()}
                {renderImageOptions()}
                {renderConstraints()}
                {renderLegend()}
                {renderHelp()}
            </div>
        </div>
    ) : null;
}