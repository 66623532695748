import React, { useState } from 'react';

import { findWhere } from 'underscore';

// Components
import { ToggleMenuComponent } from './../toggle_menu_component/toggle_menu_component';
import { isValueInRange, meanValue, roundValue } from '../../../modules/math_module';
// import { GeneralIcons } from './../icons_component/general_icons';
// import { PlotComponent } from './../../visual_analytics_component/plot';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import ReactToPrint from 'react-to-print';
// import { MeasurementsPDFExport } from './pdf_export/measurements_pdf_export';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const MeasurementsGrid = ({
        measurements
    }) => {

    const sides = HelperStudies.getDataType() === "fetal"? ["Flow", "Morphology"] : ["Right heart", "Left heart"];
    const [showSides, setShowSides] = useState(sides);
    const types = ["Direct", "Calculated"];
    const [showTypes, setShowTypes] = useState(types);
    const anatomies = HelperConfig.getAnatomies();
    const anatomies_right = anatomies.filter(a => a.sides.includes(sides[0]));
    const anatomies_left = anatomies.filter(a => a.sides.includes(sides[1]));
    const [showRight, setShowRight] = useState(anatomies_right.map(a => a.name_short));
    const [showLeft, setShowLeft] = useState(anatomies_left.map(a => a.name_short));
    
    const onActionToPerform = (action) => {
        let array = [];
        let method = () => {};

        switch (action.parameter) {
            case "sides":
                array = JSON.parse(JSON.stringify(showSides));
                method = setShowSides;
                break;
            case "types":
                array = JSON.parse(JSON.stringify(showTypes));
                method = setShowTypes;
                break;
            case "anatomies-left":
                array = JSON.parse(JSON.stringify(showLeft));
                method = setShowLeft;
                break;
            case "anatomies-right":
                array = JSON.parse(JSON.stringify(showRight));
                method = setShowRight;
                break;
            default:
                break;
        }
        
        switch (action.action) {
            case "SELECT_TAG":
                if (!array.includes(action.value)) {
                    array.push(action.value);
                }
                method(array);
                break;
            case "UNSELECT_TAG":
                const index = array.indexOf(action.value);
                if (array.length > 0 && index > -1) {
                    array.splice(index, 1);
                    method(array);
                }
                break;
            default:
                break;
        }
    }

    const getValueColor = (value, type, name) => {
        const check = HelperConfig.getMeasurementsPreference("check_normal_range");
        if (check) {
            const range = HelperConfig.getMeasurementNormalRange(type, name);
            const vir = isValueInRange(value, range);
            if (vir) {
                return HelperConfig.getMeasurementsPreference("in_range_color");
            } else if (vir === undefined) {
                return HelperConfig.getMeasurementsPreference("undefined_color");
            }
            return HelperConfig.getMeasurementsPreference("out_range_color");
        } else {
            return HelperConfig.getMeasurementsPreference("in_range_color");
        }
    }

    // --- RENDER METHODS

    const renderMeasure = (values, name, units, imtype, margin, i) => {
        const abs = HelperConfig.getMeasurementsPreference("absolute_values");
        const values_to_show = abs.includes(units)? values.map(v => Math.abs(v)) : values;
        const mean = meanValue(values_to_show);
        const mean_color = getValueColor(mean, imtype, name);
        const abv = HelperConfig.getMeasurementAbbreviation(imtype, name);
        return (
            <div key={name+i} className="grid-block horizontal " style={{ fontSize: "14px", margin }}>
                <div style={{ overflow: "hidden", fontWeight: "bold", paddingRight: "2px", marginRight: "2px" }}>{abv}: </div>
                {values_to_show.map((value, i) => {
                    const value_color = getValueColor(value, imtype, name);
                    if (i !== values_to_show.length - 1) {
                        return <>
                            <div style={{ overflow: "hidden", color: value_color, paddingRight: "2px", fontStyle: "italic" }}>{roundValue(value)}</div>
                            <div style={{ overflow: "hidden", paddingRight: "2px", fontStyle: "italic" }}>{", "}</div>
                        </>
                    } else {
                        return <div style={{ overflow: "hidden", color: value_color, paddingRight: "2px", fontStyle: "italic" }}>{roundValue(value)}</div>
                    }
                })}
                <div style={{ overflow: "hidden", paddingRight: "2px", fontStyle: "italic" }}>&nbsp;{"( "}</div>
                <div style={{ overflow: "hidden", color: mean_color, paddingRight: "2px", fontStyle: "italic", fontWeight: "bold" }}>{mean}</div>
                <div style={{ overflow: "hidden", paddingRight: "2px", fontStyle: "italic" }}>{" ) " + units}</div>
            </div>
        );
    }

    const renderMeasurements = (measurements, type) => {
        let margin = type === "direct" ? "0px 5px 8px" : "8px 5px 0px";
        return measurements.map((m,i) => {
            return renderMeasure(m.value, m.name, m.units, m.from, margin, i);
        });
    }

    const renderMeasurementsByType = (measures, color) => {
        const direct = measures.filter(m => m.type === "direct");
        const calculated = measures.filter(m => m.type === "calculated");
        let grid = "repeat(auto-fill, minmax(280px,450px))";
        let borderBottom = "";
        if (direct.length > 0){
            borderBottom = "1px solid " + color;
        }
        if (showTypes.includes("Direct") && showTypes.includes("Calculated")) {
            return (
                <React.Fragment>
                    <div style={{ borderBottom: borderBottom, display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(direct, "direct")}</div>
                    <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(calculated, "calculated")}</div>
                </React.Fragment>
            )
        } else if (showTypes.includes("Direct")  && direct.length > 0) {
            return (
                <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(direct, "direct")}</div>
            )
        } else if (showTypes.includes("Calculated") && calculated.length > 0) {
            return (
                <div style={{ display: "grid", gridTemplateColumns: grid }}>{renderMeasurements(calculated, "calculated")}</div>
            )
        }
    }
    
    const renderMeasurementsByModality = (anatomy) => {
        let padding = "10px";
        if (showTypes.includes("Calculated") && !showTypes.includes("Direct")) { padding = "3px 10px 10px" }
        if (!showTypes.includes("Calculated") && showTypes.includes("Direct")) { padding = "10px 10px 3px" }
        const measure = findWhere(measurements, { anatomy: anatomy.name_short });
        if (measure) {
            return measure.modalities.map((modality) => {
                return (
                    <div key={modality.name} style={{ display: "grid", gridTemplateColumns: "35px minmax(200px, 1fr)" }}>
                        <div style={{ color: modality.color, fontSize: "12px", margin: "10px" }}>
                            <div style={{ transform: "rotate(-90deg)", position: "relative", top: "50%" }}>
                                {modality.name}
                            </div>
                        </div>
                        <div style={{ borderTop: "1px solid " + modality.color, borderBottom: "1px solid " + modality.color, margin: "0px 20px 10px 0px", padding }}>
                            {/* <PlotComponent
                                show_right={this.state.show_right}
                                show_left={this.state.show_left}
                                measurements={modality.measurements}
                                plot_type={"flow"}
                                analysis={this.props.analysis}
                                modality={modality}
                            /> */}
                            {renderMeasurementsByType(modality.measurements, modality.color)}
                        </div>
                    </div>
                )
            })
        }
    }

    const renderAnatomyBoxes = (side) => {
        const anatomies = side === sides[0]? anatomies_right : anatomies_left;
        const anatomies_show = side === sides[0]? showRight : showLeft;
        return anatomies.map((anatomy) => {
            if (anatomies_show.includes(anatomy.name_short)) {
                return (
                    <div className={"anatomy-box"} key={anatomy.name_short}>
                        <div style={{ textAlign: "center", margin: "10px 10px 15px" }}>
                            {anatomy.name_short}
                            <span style={{ marginLeft: "8px", fontStyle: "italic", color: "#ccc", fontSize: "12px" }}>{anatomy.name_long}</span>
                        </div>
                        {renderMeasurementsByModality(anatomy)}
                    </div>
                )
            }
        })
    }

    const renderHeartSide = (side) => {
        const data = side === sides[0]? anatomies_right.map(a => a.name_short) : anatomies_left.map(a => a.name_short);
        const data_selected = side === sides[0]? showRight : showLeft;
        const parameter = side === sides[0]? "anatomies-right" : "anatomies-left";
        if (showSides.includes(side)) {
            return (
                <div className={side + " side"} key={side}>
                    <div className="side-title">{side.toUpperCase()}</div>
                    <ToggleMenuComponent
                        site={"measurements-anatomies"}
                        data={data}
                        data_hidden={[]}
                        keys_selected={data_selected}
                        on_action_to_perform={onActionToPerform}
                        parameter={parameter}
                    />
                    {renderAnatomyBoxes(side)}
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="header-menus">
                <div className="menu-title">Sides:</div>
                <ToggleMenuComponent
                    site={"measurements-header"}
                    data={sides}
                    data_hidden={[]}
                    keys_selected={showSides}
                    on_action_to_perform={onActionToPerform}
                    parameter={"sides"}

                />
                <div className="menu-title">Measurements:</div>
                <ToggleMenuComponent
                    site={"measurements-header"}
                    data={types}
                    data_hidden={[]}
                    keys_selected={showTypes}
                    on_action_to_perform={onActionToPerform}
                    parameter={"types"}

                />
                <div className="export-button">
                    {/* <ReactToPrint
                        content={() => componentRef}
                        trigger={() => <GeneralIcons type="export" width="36" height="36" style={{ stroke: "#c62828" }} />}
                    /> */}
                    {/* <PDFDownloadLink
                        document={<MeasurementsPDFExport />}
                        fileName={HelperStudies.getStudyId() + "_Measurements_Export.pdf"}
                    >
                        <GeneralIcons type="export" width="36" height="36" style={{ stroke: "#c62828" }} />
                    </PDFDownloadLink> */}
                </div>
            </div>
            <div className="grid"> {/* ref={(response) => (componentRef = response)}> */}
                {sides.map(side => {
                    return renderHeartSide(side);
                })}
            </div>
        </React.Fragment>
    );
}
