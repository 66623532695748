export class HelperStudiesState {
    static init(site, study_info){
        return {
            site: site,
            currentproject: study_info.project,
            study_id: study_info.study_id,
            study_date: study_info.study_date,
            patient_id: study_info.patient_id,
            patient_id_show: study_info.patient_id_show,
            data_type: study_info.data_type,
            editor: study_info.editor
        }
    }
}