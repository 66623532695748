import { HelperConfig } from './../helper_config/helper_config';
import { HelperImages } from './../helper_images/helper_images';
import { HelperSegmentation } from './helper_segmentation';

import { getSpline, getNextEvent } from './../../modules/spline_module';

import { findWhere, findIndex } from 'underscore';

export class HelperSegmentationMMode {

    // INIT METHODS --------------------------

    static init(image) {
        const shapes = HelperConfig.getShapes(image.type, "normal", false);
        const lines = HelperConfig.getLines(image.type, "normal", false);
        let current_action = "finished";
        let current_shape = shapes[0];
        let current_line = shapes[0].lines[0];
        if (image.segmentation.length === 0 || image.segmentation[0].lines.length !== lines.length) {
            current_action = "segmenting";
            const segmentation_lines = [];
            lines.map(line => {
                const shape = shapes.filter(s => line.includes(s.name));
                segmentation_lines.push({
                    name: line,
                    finished: false,
                    shape: shape[0].name,
                    points: []
                });
            });
            image.segmentation = [{ lines: segmentation_lines }];
        } else if (!image.is_segmented) {
            const init = HelperSegmentation.initTimeEvent(image, 0, false);
            current_action = init.current_action;
            current_line = init.current_line;
            current_shape = init.current_shape;
        }
        return {
            current_cycle: 0,
            current_event: false,
            control_points: false,
            current_cp: false,
            current_action,
            current_shape,
            current_line,
        }
    }
    
    // SEGMENTATION METHODS --------------------------

    static finish(image, type, state, finished) {
        const config = HelperConfig.getToolConfig("M-Mode","normal");
        if (type === "task") {
            if (state.current_action === "segmenting" && finished) {
                const line = HelperSegmentation.getCurrentLine(image, state);
                line.finished = true;
                if (config.auto_edit_segmentation) {
                    return { current_action: "editing-segmentation", current_cp: false };
                }
            }
            return HelperSegmentation.initTimeEvent(image, state.current_cycle, state.current_event);
        } else if (type === "all") {
            return { current_action: "finished" };
        }
    }

    // EDIT METHODS --------------------------

    static moveSelectedPoint(image, state) {
        const selected = state.element_selected;
        selected.x = Math.round(state.mouse_allowed.pixel.x);
        selected.y = Math.round(state.mouse_allowed.pixel.y);
        if (HelperSegmentation.getSegmentationLines(image).length === 4) {
            this.spreadChangesLinkedControlPoints(image, state);
        }
    }
    
    static spreadChangesLinkedControlPoints(image, state) {
        const lines = HelperConfig.getLines(image.type, "normal", false);
        const current_line = lines.indexOf(state.current_line);
        const linked_line = !!(current_line % 2)? current_line-1 : current_line+1;
        const linked_points = HelperSegmentation.getCurrentLine(image, { current_line: lines[linked_line] }).points;
        if (linked_points.length > 0) {
            const linked_point = findWhere(linked_points, { id: state.element_selected.id });
            if (linked_point) {
                linked_point.x = state.element_selected.x;
            }
        }
    }

    // DRAW METHODS --------------------------

    static checkMouseAllowance(image, state, element) {
        const mouse = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
        const mouse_allowed = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
        const points = HelperSegmentation.getCurrentLine(image, state).points;
        let reference_points = [];
        if (HelperSegmentation.getSegmentationLines(image).length === 4) {
            const lines = HelperConfig.getLines(image.type, "normal", false);
            const current_line = lines.indexOf(state.current_line);
            const reference_line = !!(current_line % 2)? current_line-1 : current_line+1;
            reference_points = HelperSegmentation.getCurrentLine(image, { current_line: lines[reference_line] }).points;
        }
        if (state.current_action === "segmenting" && reference_points.length > 0) {
            mouse_allowed.x = reference_points[points.length].x;
        } else {
            const events_of_interest = ["end_diastole_start","end_diastole_end","end_systole"];
            const threshold = HelperConfig.getToolConfig("M-Mode","normal").event_threshold;
            let prev_event;
            let next_event;
            if (state.current_action === "segmenting") {
                prev_event = points.length > 0? points[points.length-1].x : getNextEvent(image.cardiac_events, 0, events_of_interest).x;
                next_event = points.length > 0? getNextEvent(image.cardiac_events, prev_event, events_of_interest).x : prev_event;
            } else if (state.element_selected) {
                const event = getNextEvent(image.cardiac_events, state.element_selected.x-1, events_of_interest).x;
                if (state.element_selected.x === event) {
                    prev_event = event;
                    next_event = event;
                } else {
                    const index = findIndex(points, { id: state.element_selected.id });
                    prev_event = points[index-1].x;
                    next_event = points[index+1].x;
                }
            }
            mouse_allowed.x = mouse.x < prev_event+threshold?
                                (prev_event === next_event? prev_event : 
                                    (prev_event+threshold > next_event ? next_event : prev_event+threshold)
                                )
                            : mouse.x > next_event-threshold?
                                (state.current_action === "segmenting" || prev_event === next_event? next_event : next_event-threshold)
                            : mouse.x;
        }
        const mouse_allowed_canvas = HelperImages.pixelToCanvas(element, { x: mouse_allowed.x, y: mouse_allowed.y });
        return { mouse_allowed: { pixel: mouse_allowed, canvas: mouse_allowed_canvas }};
    }

    static drawSplines(lines, context, element) {
        const colors = HelperConfig.getStyle("segmentation", "line_colors");
        const style = HelperConfig.getStyle("segmentation", "spline");
        for (const line of lines) {
            if (line.points.length > 1) {
                const color = colors[findIndex(lines, { name: line.name })];
                const canvas = [];
                for (const point of line.points) {
                    canvas.push(HelperImages.pixelToCanvas(element, { x: point.x, y: point.y }));
                }
                const spline = getSpline(canvas, "cardinal");
                context.lineWidth = style.width;
                context.strokeStyle = color;
                context.beginPath();
                for (const i in spline) {
                    if (i === 0) {
                        context.moveTo(spline[i].x, spline[i].y);
                    } else {
                        context.lineTo(spline[i].x, spline[i].y);
                    }
                }
                context.stroke();
            }
        }            
    }

}