import Store from './../../models/store'

export class HelperStudiesStore {
    static setCurrentProject(project) {
        Store.setCurrentProject(project);
    }
    static setCurrentProjectObj(project) {
        Store.setCurrentProjectObj(project);
    }
    static setCurrentProjectStudies(studies) {
        Store.setCurrentProjectStudies(studies);
    }
    static setStudyId(study_id){
        Store.setStudyId(study_id);
    }
    static setStudyDate(study_date){
        Store.setStudyDate(study_date);
    }
    static setPatientId(patient_id){
        Store.setPatientId(patient_id);
    }
    static setPatientIdShow(patient_id_show){
        Store.setPatientIdShow(patient_id_show);
    }
    static setDataType(data_type){
        Store.setDataType(data_type);
    }
    static setStudyState(study_state){
        Store.setStudyState(study_state);
    }
    static setStudyImages(images){
        Store.setStudyImages(images);
    }
    static setOriginalAnalysisImages(images){
        Store.setOriginalAnalysisImages(images);
    }
    static setImagesToLoad(images){
        Store.setImagesToLoad(images);
    }
    static setCurrentPage(page) {
        Store.setCurrentPage(page);
    }
    static setCurrentSorting(sort) {
        Store.setCurrentSorting(sort);
    }
    static setEditor(editor) {
        Store.setEditor(editor);
    }
    static setValidated(validated) {
        Store.setValidated(validated);
    }
    static setHideComplete(hide) {
        Store.setHideComplete(hide);
    }
    static getHideComplete() {
        return Store.getHideComplete();
    }
    static getValidated() {
        return Store.getValidated();
    }
    static getEditor() {
        return Store.getEditor();
    }
    static getCurrentProject() {
        return Store.getCurrentProject();
    }
    static getCurrentProjectObj() {
        return Store.getCurrentProjectObj();
    }
    static getCurrentProjectStudies() {
        return Store.getCurrentProjectStudies();
    }
    static getStudyId() {
        return Store.getStudyId();
    }
    static getStudyDate() {
        return Store.getStudyDate();
    }
    static getPatientId() {
        return Store.getPatientId();
    }
    static getPatientIdShow() {
        return Store.getPatientIdShow();
    }
    static getDataType() {
        return Store.getDataType();
    }
    static getStudyState(){
        return Store.getStudyState();
    }
    static getStudyImages() {
        return Store.getStudyImages();
    }
    static getOriginalAnalysisImages(){
        return Store.getOriginalAnalysisImages();
    }
    static getImagesToLoad() {
        return Store.getImagesToLoad();
    }
    static getCurrentPage() {
        return Store.getCurrentPage();
    }
    static getCurrentSorting() {
        return Store.getCurrentSorting();
    }
}